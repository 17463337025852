var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.modeWrap},[_c('div',{class:_vm.$style.modeBox},[_c('div',{ref:"boxRef"},[_c('div',{class:_vm.$style.header},[(!_vm.infoDisabled)?_c('div',{class:_vm.$style.info},[_c('span',{class:_vm.$style.infoLabel},[_vm._v(_vm._s(_vm.currentCameraData.cameraName)+_vm._s(("" + (_vm.currentCameraData.buildingName ? ("（" + (_vm.currentCameraData.buildingName) + "）") : ''))))]),_c('span',{class:[_vm.$style.infoLabel, _vm.$style.otherLabel]},[_vm._v(_vm._s(_vm.currentCameraData.projectName))])]):_vm._e(),_c('span',{class:_vm.$style.title},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{class:_vm.$style.operation},[_c('i',{staticClass:"TC",class:[
              _vm.$style.otherIcon,
              _vm.isFullscreen ? 'tc-icon-huanyuanhuabu' : 'tc-icon-quanping' ],on:{"click":_vm.handleFullScreen}})])]),_c('div',{class:[_vm.$style.main, _vm.isFullscreen ? _vm.$style.mainFullScreen : '']},[_c('div',{class:[
            _vm.$style.videoBox,
            _vm.model.label === 'MODEL_FOUR' ? _vm.$style.fourBox : '',
            _vm.model.label === 'MODEL_NINE' ||
            _vm.model.label === 'MODEL_LEFT' ||
            _vm.model.label === 'MODEL_RIGHT'
              ? _vm.$style.nineBox
              : '',
            _vm.model.label === 'MODEL_TWELVE' || _vm.model.label === 'MODEL_MAIN'
              ? _vm.$style.twelveBox
              : '' ]},_vm._l((_vm.cameras),function(item,index){return _c('video-box',{key:item.id,staticClass:"camera-video-box",class:[
              _vm.model.label === 'MODEL_MAIN' && index === 0
                ? _vm.$style.boxOnePointer
                : '',
              _vm.model.label === 'MODEL_LEFT' && index === 0
                ? _vm.$style.boxOneLeftMain
                : '',
              _vm.model.label === 'MODEL_RIGHT' && index === 0
                ? _vm.$style.boxOneRightMain
                : '' ]},[(item.url)?_c('video-view',{attrs:{"url":item.url,"accessToken":item.token,"data":item}}):_vm._e()],1)}),1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }