<template>
  <div :class="$style.modeWrap">
    <div :class="$style.modeBox">
      <div ref="boxRef">
        <div :class="$style.header">
          <div :class="$style.info" v-if="!infoDisabled">
            <span :class="$style.infoLabel"
              >{{ currentCameraData.cameraName
              }}{{
                `${
                  currentCameraData.buildingName
                    ? `（${currentCameraData.buildingName}）`
                    : ''
                }`
              }}</span
            >
            <span :class="[$style.infoLabel, $style.otherLabel]">{{
              currentCameraData.projectName
            }}</span>
          </div>
          <span :class="$style.title">
            {{ title }}
          </span>
          <div :class="$style.operation">
            <i
              class="TC"
              :class="[
                $style.otherIcon,
                isFullscreen ? 'tc-icon-huanyuanhuabu' : 'tc-icon-quanping',
              ]"
              @click="handleFullScreen"
            />
          </div>
        </div>
        <div :class="[$style.main, isFullscreen ? $style.mainFullScreen : '']">
          <div
            :class="[
              $style.videoBox,
              model.label === 'MODEL_FOUR' ? $style.fourBox : '',
              model.label === 'MODEL_NINE' ||
              model.label === 'MODEL_LEFT' ||
              model.label === 'MODEL_RIGHT'
                ? $style.nineBox
                : '',
              model.label === 'MODEL_TWELVE' || model.label === 'MODEL_MAIN'
                ? $style.twelveBox
                : '',
            ]"
          >
            <video-box
              class="camera-video-box"
              :class="[
                model.label === 'MODEL_MAIN' && index === 0
                  ? $style.boxOnePointer
                  : '',
                model.label === 'MODEL_LEFT' && index === 0
                  ? $style.boxOneLeftMain
                  : '',
                model.label === 'MODEL_RIGHT' && index === 0
                  ? $style.boxOneRightMain
                  : '',
              ]"
              v-for="(item, index) in cameras"
              :key="item.id"
            >
              <video-view
                v-if="item.url"
                :url="item.url"
                :accessToken="item.token"
                :data="item"
              />
            </video-box>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';
import { Component, Vue } from 'vue-property-decorator';
import VideoBox from '../show/video-box.vue';
import VideoView from '@/components/video-view';
import { emitter, CAMETA_VIEW_SHOW, MODEL } from '../edit/final';
import screenfull from '@/utils/fullscreen';
import {
  getViewPublicDetails,
  getShareCameraViewMode,
} from '@/services/monitor/camera-view-list';
import { flexDpr } from '@/assets/js/flex-dpr.js';

flexDpr();

const KEY_MODEL = {
  /** @name 普通 */
  MODEL_NORMAL: '普通',
  /** @name 分享 */
  MODEL_SHARE: '分享',
};

const source = {
  shareCamera: {
    name: KEY_MODEL.MODEL_SHARE,
  },
  showCameraView: {
    name: KEY_MODEL.MODEL_NORMAL,
  },
};

@Component({
  components: {
    VideoBox,
    VideoView,
  },
})
export default class PersonView extends Vue {
  get source() {
    const path = this.$route.path;
    let result = '';
    Object.keys(source).forEach(v => {
      if (path.includes(v)) {
        result = source[v];
      }
    });
    return result;
  }
  checkURL() {
    switch (this.source.name) {
      case KEY_MODEL.MODEL_SHARE:
        this.fetchNoAuthData();
        break;
      case KEY_MODEL.MODEL_NORMAL:
        this.fetchData();
        break;
      default:
        this.fetchData();
        break;
    }
  }
  created() {
    this.checkURL();
  }
  mounted() {
    this.handleKeyEscEvent();
    emitter.on(CAMETA_VIEW_SHOW, this.handleCameraViewShow);
  }

  modelList = [];
  MODEL = MODEL;
  title = '';
  result = {};
  async fetchData() {
    const result = await getViewPublicDetails(this.$route.params.id);
    this.modelList = result.model;
    this.result = result;
    this.title = result.modelName;
  }

  /**
   * @name 访客信息
   */
  get visitorInfo() {
    return this.$store.state.generalScreen.visitorInfo;
  }
  async fetchNoAuthData() {
    try {
      const result = await getShareCameraViewMode({
        shareId: this.$route.params.id,
        password: this.visitorInfo ? this.visitorInfo.password : '',
      });
      this.modelList = result.model;
      this.result = result;
      this.title = result.modelName;
    } catch (error) {
      if (error.code === 10031) {
        this.$router.replace(this.visitorInfo ? this.visitorInfo.url : '');
      }
    }
  }
  get model() {
    const label = this.result?.modelLabel || '';
    let index = -1;
    Object.values(MODEL).forEach((item, itemIndex) => {
      if (item.label === label) {
        index = itemIndex;
      }
    });
    return index === -1 ? { lable: '', name: '', length: 0 } : MODEL[index];
  }
  get cameras() {
    const array = [];
    for (let i = 0; i < this.model.length; i++) {
      array.push({
        id: uuidv4(),
      });
    }
    this.modelList.forEach(item => {
      array[item.number] = {
        ...array[item.number],
        ...item,
        // url: 'ezopen://WSDRAB@open.ys7.com/F30203581/1.hd.live',
        // token:
        //   'at.6wz0zt03d4tifanxdmh44qgn7o47q41t-968n2v5ib7-00xwbtq-foadirsso',
      };
    });
    return array;
  }

  infoDisabled = true;
  currentCameraData = {};
  handleCameraViewShow(item) {
    this.currentCameraData = item.data;
    if (item.model === 'enter') {
      this.infoDisabled = false;
    } else {
      this.infoDisabled = true;
    }
  }

  isFullscreen = false;
  handleFullScreen() {
    const id = this.$refs.boxRef;
    if (screenfull.element === id && screenfull.isFullscreen) {
      screenfull.exit();
      this.isFullscreen = false;
    } else {
      screenfull.request(id);
      this.isFullscreen = true;
    }
  }
  handleKeyEscEvent() {
    document.addEventListener('fullscreenchange', () => {
      if (!screenfull.isFullscreen && this.isFullscreen) {
        this.isFullscreen = false;
      }
    });
  }
}
</script>
<style lang="less" module>
@bgColor: #02051b;
@fontColor: rgba(5, 211, 255, 1);
@lineColor5: rgba(13, 200, 254, 0.5);
@lineColor: rgba(13, 200, 254, 1);
@lightLineColor: rgba(13, 200, 254, 0.5);
@boxshadow: 0px 0px 18px 2px rgba(12, 181, 232, 0.4) inset;
.modeWrap {
  background: @bgColor;
  width: 100%;
  height: 100vh;
  padding: 0.15rem;
  box-sizing: border-box;

  .modeBox {
    border: 0.01rem solid @fontColor;
  }

  .header {
    height: 0.79rem;
    border-bottom: 0.01rem solid @lineColor5;
    position: relative;
    .info {
      position: absolute;
      left: 20px;
      top: 18px;
      .infoLabel {
        font-size: 0.18rem;
        display: block;
        color: #ffffff;
      }
      .otherLabel {
        color: #ffffff;
        font-size: 0.14rem;
        padding-top: 0.05rem;
      }
    }
    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      padding: 0 0.4rem;
      font-size: 0.36rem;
      color: @fontColor;
      text-align: center;
      &:hover {
        .icon {
          display: inline-block;
        }
      }
      .icon {
        display: none;
      }
    }
    .operation {
      position: absolute;
      right: 0.2rem;
      top: 50%;
      transform: translateY(-50%);
      .otherIcon {
        font-size: 0.2rem;
        color: #fff;
        cursor: pointer;
      }
      .otherIcon + .otherIcon {
        margin-left: 0.3rem;
      }
    }
  }

  .main {
    height: calc(100vh - 1.1rem);
    padding: 0.2rem;
    &.mainFullScreen {
      height: calc(100vh - 0.8rem);
    }
    .videoBox {
      display: grid;
      grid-row-gap: 0.2rem;
      grid-column-gap: 0.2rem;
      height: 100%;
      // 四分屏
      &.fourBox {
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
      }
      // 九分屏
      &.nineBox {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr;
      }
      // 十二分屏
      &.twelveBox {
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
      &.oneBox {
        height: 100%;
        width: 100%;
      }
      // 焦点模式
      .boxOnePointer {
        grid-column-start: 2;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
      }
      // 主次模式-左大
      .boxOneLeftMain {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 4;
      }
      // 主次模式-右大
      .boxOneRightMain {
        grid-column-start: 2;
        grid-column-end: 5;
        grid-row-start: 1;
        grid-row-end: 4;
      }
    }
  }

  .footer {
    height: 0.7rem;
    line-height: 0.7rem;
    margin-top: -0.2rem;
    display: flex;
    align-items: center;
    .btn {
      display: inline-block;
      padding: 0.08rem 0.14rem;
      text-align: center;
      height: 0.32rem;
      line-height: 1;
      background-color: #ffffff;
      margin-left: 0.2rem;
      color: @fontColor;
      border-radius: 0.04rem;
      cursor: pointer;
      font-size: 0.16rem;

      &.actived {
        color: #fff;
        background-color: @fontColor;
      }
    }
    .button {
      margin-left: 0.2rem;
      color: @fontColor;
      cursor: pointer;
      font-size: 0.16rem;
    }
  }
}
.warningModal {
  :global(.ant-modal-body) {
    padding-top: 0.15rem;
    margin-bottom: 0.15rem;
  }
  :global(.ant-modal-content) {
    background-color: @bgColor;
    border-radius: unset;
  }
  :global(.ant-modal-header) {
    color: @fontColor;
    background: linear-gradient(
      90deg,
      rgba(5, 211, 255, 0.2) 0%,
      transparent 98%
    );
    border: 1px solid @lightLineColor;
    box-shadow: @boxshadow;
    padding: 0.1rem 0.14rem;
    font-size: 0.14rem;
    border-radius: unset;
  }
  :global(.ant-modal-title) {
    color: @fontColor;
    font-weight: 200;
    font-size: 0.14rem;
    text-align: left;
  }
  :global(.ant-modal-close-x) {
    width: 0.4rem;
    height: 0.34rem;
    line-height: 0.34rem;
    color: @fontColor;
    font-size: 0.14rem;
    padding: 0.05rem 0.14rem;
  }
  :global(.ant-modal-close-x .anticon) {
    vertical-align: unset;
  }
}
</style>
